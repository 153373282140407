<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header d-flex justify-content-between ">
          <h3 class="pt-2">
             {{ lista_campos != "" ? "Editar |" : "Criar |" }} ingresso
          </h3>
          <div class="col-md-8">
             
     
                <label class="col-md-12">Eventos:*</label>
                <treeselect
                disabled
                  :multiple="false"
                  :options="lista_eventos"
                  placeholder="Selecione o evento..."
                  v-model="form.eventos_id"
                />
              </div>
        </div>
        <div class="card-body table-responsive">
          <form
            action=""
            @submit.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')"
          >
            <div class="form-group row">
              
                <div class="col-md-3">
                <label class="col-md-12">Titulo:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.tipo"
                  placeholder="Digite o tipo do Ingresso"
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">Tipo:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.tipo2"
                  placeholder="Digite o tipo do Ingresso"
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">Lote:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.lote"
                  placeholder="Digite o lote do Ingresso"
                />
              </div>
              
              <div class="col-md-3">
                <label class="col-md-12">Valor:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.valor"
                  placeholder="Digite o Valor do Ingresso"
                  @input="valorFormat"
                  v-mask="variableWithMask"
                />
              </div>
              <div class="col-md-6 pt-1">
                <label class="col-md-12">Quantidade lote:*</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.quantidade_lote"
                  placeholder="Quantidade de Ingresso do lote"
                />
              </div>
              
             
              <div class="col-md-6 pt-1">
                <label class="col-md-12">Setor:*</label>
                <treeselect
                  :multiple="false"
                  :options="lista_setor"
                  placeholder="Selecione o evento..."
                  v-model="form.setor_id"
                />
              </div>
              <div class="col-md-6 pt-1">
                <label class="col-md-12">Obs :*</label>
                <input
                  
                  type="text"
                  class="form-control"
                  v-model="form.obs"
                  placeholder="Digite a Obs do ingresso"
                />
              </div>
              <div class="col-md-6 pt-1">
                <label class="col-md-12">Obs 2 :*</label>
                <input
                  
                  type="text"
                  class="form-control"
                  v-model="form.obs2"
                  placeholder="Digite a Obs do ingresso"
                />
              </div>
            </div>
            <div class="form-group row pt-1">
              <div class="col-md-12">
                <label class="col-md-12">Descrição 1:*</label>
                <input
                  
                  type="text"
                  class="form-control"
                  v-model="form.desc1"
                  placeholder="Digite a primeira descrição do ingresso"
                />
              </div>
              <div class="col-md-12 pt-2">
                <label class="col-md-12">Descrição 2:*</label>
                <input
                  
                  type="text"
                  class="form-control"
                  v-model="form.desc2"
                  placeholder="Digite a segunda descrição do ingresso"
                />
              </div>
              <div class="col-md-12 pt-2">
                <label class="col-md-12">Descrição 3:*</label>
                <input
                  
                  type="text"
                  class="form-control"
                  v-model="form.desc3"
                  placeholder="Digite a terceira descrição do ingresso"
                />
              </div>
              <div class="col-md-12 pt-2">
                <label class="col-md-12">cor:*</label>
               <select required v-model="form.cor" class="form-control">
              <option value="l-bg-green-dark">verde</option>
              <option value="l-bg-orange-dark">laranja</option>
              <option value="l-bg-blue-dark">azul</option>
              <option value="l-bg-cherry ">roxo</option>

              </select>
              </div>
            </div>
            <div class="row form-group col-md-12">
              <div class="col-md-10 pull-left">
                <div class="md-radio-inline text-uppercase">
                  <label>Status:*</label>
                  <div class="space d-flex">
                    <b-form-radio
                      :inline="true"
                      value="2"
                      v-model="form.status"
                    >
                      inativo
                    </b-form-radio>
                    <b-form-radio
                      :inline="true"
                      value="1"
                      v-model="form.status"
                    >
                      ativo
                    </b-form-radio>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button type="submit" class="btn btn-primary" :disabled="verif">
                  Salvar
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import EventoService from "@/core/services/evento.service";
export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        tipo: null,
        tipo2: null,
        quantidade_lote :null,
        lote: null,
        valor: null,
        obs: "",
        obs2: "",


        desc1: "",
        desc2: "",
        desc3: "",
        cor: "",
        status:1,

        eventos_id:null,
        setor_id:null,
      },
      variableWithMask: "",
      eventos_id:  EventoService.getEventoId(),
      verif: false,
    };
  },
  created() {
    this.preenxerCampos();
  },
  computed: {
    lista_eventos(){
      return this.$store.state.evento.lista_eventos.map((cat) => ({
        id: cat.id,
        label:cat.id +' - '+ cat.nome_evento  ,
      }));
  },  lista_setor() {
    
      let setor =  this.$store.state.setor.lista_setor.filter((item) => {
        if (!this.eventos_id) return true;
        return (
          String(item["eventos_id"])
            .toLowerCase()
            .indexOf(this.eventos_id + "".toLowerCase()) !== -1
        );
      });;
      return setor.map((cat) => ({
        id: cat.id,
        label: cat.id +' - '+ cat.nome,
      }));
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.ingressos.mensagem_alert;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` um ingresso no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      this.form.valor =   parseFloat(
        this.form.valor.replace("R$", "").replace(",", ".")
      );
      await this.$store.dispatch("ingressos/create_ingresso", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "ingressos",
      });
    },
    async update() {
      this.form.valor =   parseFloat(
        this.form.valor.replace("R$", "").replace(",", ".")
      );
      this.verif = true;
      await this.$store.dispatch("ingressos/update_ingresso", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "ingressos",
      });
    },
 async   preenxerCampos() {
       this.$store.dispatch("evento/listar_eventos");
     
       await this.$store.dispatch("setor/listar_setor")

      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          tipo: this.lista_campos.tipo,
          lote: this.lista_campos.lote,
          valor: this.lista_campos.valor,
          obs: this.lista_campos.obs,
          obs2: this.lista_campos.obs2,

          desc1: this.lista_campos.desc1,
          desc2: this.lista_campos.desc2,
          desc3: this.lista_campos.desc3,
          eventos_id:this.lista_campos.eventos_id,
          setor_id:this.lista_campos.setor_id,
          tipo2:this.lista_campos.tipo2,
          cor:this.lista_campos.cor,
          status:this.lista_campos.status

        };

        if(this.$route.params.eventos_id){
          this.form.eventos_id  =this.$route.params.eventos_id
        }
    },
    valorFormat(index) {
      let pa = "";
      for (let i = 0; i < this.form.valor.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMask = "R$" + pa + "#,##";
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>